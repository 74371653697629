.program-image {
  transition: transform 0.2s;
  opacity: 0.9;
  border-radius: 2%;
  margin: auto;
  display: block;
}

.program-image:hover {
  transform: scale(1.1);
}

.title {
  animation: fadeIn 1s;
}

.description {
  animation: fadeIn 2s;
}

.points {
  list-style: none;
}

.point {
  position: relative;
  margin-left: 2em;
  animation: fadeIn 3s;
}

.point:before {
  content: "";
  position: absolute;
  left: -2em;
  top: 0.5em;
  width: 1em;
  height: 1em;
  background-image: url('../assets/icons/arrow-right.png');
  background-size: cover;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

h2 {
  font-size: 1.2em;
  color: #333;
  margin-bottom: 1em;
}

.quote-banner {
  background-color: #f9f9f9;
  padding: 20px;
  margin: 20px 0;
  border-radius: 50px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1), 0px 10px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  border: 1px solid #ddd;
  background-image: url('../assets/icons/paper-purple-texture.png');
}

.quote {
  font-style: italic;
  color: #333;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}